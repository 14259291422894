import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="বাংলা বর্ণ চিনি ও বাংলা পড়তে শিখি (প্রেসেন্টেশন)" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  বাংলা বর্ণ চিনি ও বাংলা পড়তে শিখি (প্রেসেন্টেশন)
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/bengali',
        name: 'বাংলা'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`শুধু বাংলা বর্ণমালার বই দিয়ে বাচ্চাদের বাংলা শিখানো ইদানিং খুব কষ্টসাধ্য বিষয়। তাই আমরা তৈরি করেছি কিছু মজার প্রেসেন্টেশন। এনিমেশন+সাউন্ড+গেইম থাকায় বাচ্চারা বেশ মজা করে এখন শিখতে পারবে বাংলা বর্ণমালা ও শব্দ গঠন প্রক্রিয়া, ইন শা আল্লাহ।`}</p>
      <p>{`এখানে আছে মোট ১১ টি প্রেসেন্টেশন। যার প্রথম ৯ টি প্রেসেন্টেশন দেখালে বাচ্চারা শিখবে বাংলা ৫০ টি বর্ণ, এবং বাকি ২ টা প্রেসেন্টেশন দেখালে বাচ্চারা শিখে যাবে কিভাবে শব্দ তৈরি করতে হয়, ইন শা আল্লাহ।`}</p>
      <p>{`নির্ধারিত ফি দিয়ে প্রেসেন্টেশনগুলো ডাউনলোড করতে কল বা হোয়াটস-আপ করুন এখানে: 01911-494547`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1Y052Cl_wAWar5UtnC0gAoM3QcnSau95o/edit#slide=id.p1"
          }}>{`১. স্বরবর্ণ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1-r912_cvc4xg3Y9HWMl9UPlvuRk84e5E/edit?usp=sharing&ouid=106179897632712562234&rtpof=true&sd=true"
          }}>{`২. ক-বর্গ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/131RIHU1X0sgZ3H5kb8kue0BsjPax5nqa/edit#slide=id.p1"
          }}>{`৩. চ-বর্গ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1F2Qb_vdM09Qs-aoJER04jBl1W5yHcpw6/edit#slide=id.p1"
          }}>{`৪. ট-বর্গ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1c2Lmt90Wv3gXae7RqK8QGo6oQzH_Lahm/edit#slide=id.p1"
          }}>{`৫. ত-বর্গ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1sLoIY2rwSQOAfO2VY0Oe9tinH-kQSzre/edit#slide=id.p1"
          }}>{`৬. প-বর্গ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1XMmGLlDdb_TOYW4W6JxV89M4CE10NoKF/edit#slide=id.p1"
          }}>{`৭. য-স ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1s0RD5pmiUitzYYjgE-XGahGWf3RQjW55/edit#slide=id.p1"
          }}>{`৮. হ-য় ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/14NKa_DaWGaBkj3lZm85z_ws7lA_jSdbk/edit#slide=id.p1"
          }}>{`৯. ৎ - ঁ ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1DAxNRrA6QM56AJ1Bf07ngBu24zHhmZP-/edit#slide=id.p1"
          }}>{`১০. কার ও শব্দ গঠন - আকার-ঊকার ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://docs.google.com/presentation/d/1JV4vbgC6sOVAoWmOtgIRdvxXhpEGEbnP/edit#slide=id.p1"
          }}>{`১১. কার ও শব্দ গঠন - ঋকার-ঔকার ডাউনলোড`}</a></li>
      </ul>
      <Video videoSrcURL="https://www.youtube.com/embed/FArIDxr45jM" videoTitle="কীভাবে বাংলা পড়তে শেখাবেন" frameWidth="720px" frameHeight="504px" width="100%" mb={4} mt={4} mdxType="Video" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      